<template>
  <div class="login">
    <div class="login-form">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item label="" prop="mobile">
          <div class="form-bg">
            <el-input
              v-model.trim="ruleForm.mobile"
              placeholder="phone"
              :readonly="is_bind"
            >
              <template slot="prepend">84 - </template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item v-if="!is_bind">
          <el-col :span="16">
            <el-form-item prop="code">
              <div class="form-bg">
                <el-input
                  v-model.trim="ruleForm.code"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  placeholder="请输入验证码"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
          <el-col :span="7">
            <el-button class="getCode" type="primary" @click="getCode()">
              {{ codeTime > 0 ? codeTime : "获取验证码" }}
            </el-button>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :disabled="is_bind && is_receive"
            v-loading.fullscreen.lock="fullscreenLoading"
          >
            {{ bindText }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  mixins: [],
  props: {},
  data() {
    return {
      codeTime: 0,
      ruleForm: {
        mobile: "",
        code: "",
      },
      rules: {
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      info: {},
      bindText: "绑定并领取",
      is_bind: false,
      is_receive: false,
      fullscreenLoading: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.info = this.$route.query;
    this.getData();
  },
  mounted() {},
  methods: {
    getData() {
      this.fullscreenLoading = true;
      let params = {
        appid: this.info.appid,
        userid: this.info.userid,
        token: this.info.token,
      };
      this.$http.post("/api/getUserBindInfo", params).then((res) => {
        if (res.code === 200) {
          this.ruleForm = res.data;
          let { is_bind, is_receive } = res.data;
          this.is_bind = is_bind ? true : false;
          this.is_receive = is_receive ? true : false;
          if (is_bind && is_receive) {
            this.bindText = "已领取";
          } else if (!is_bind && !is_receive) {
            this.bindText = "绑定并领取";
          } else if (is_bind && !is_receive) {
            this.bindText = "领取";
          }
          this.fullscreenLoading = false;
        } else {
          this.$message.error(res.msg);
          this.fullscreenLoading = false;
        }
      });
    },
    getCode() {
      if (this.ruleForm.mobile === "") {
        this.$message.warning("请输入手机号");
        return;
      }
      if (this.codeTime <= 59 && this.codeTime > 0) {
        return;
      }
      let params = {
        appid: this.info.appid,
        userid: this.info.userid,
        token: this.info.token,
        mobile: this.ruleForm.mobile,
      };
      this.$http.post("/api/sendMobileCode", params).then((res) => {
        if (res.code === 200) {
          this.codeTime = 59;
          let codeTimeInt = setInterval(() => {
            this.codeTime = this.codeTime - 1;
            if (this.codeTime == 0) {
              clearInterval(codeTimeInt);
            }
          }, 1000);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    submitForm(formName) {
      var _this = this;
      this.fullscreenLoading = true;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            appid: this.info.appid,
            userid: this.info.userid,
            token: this.info.token,
            mobile: this.ruleForm.mobile,
            code: this.ruleForm.code,
            server_id: this.info.server_id,
            role_id: this.info.role_id,
          };
          this.$http.post("/api/bindMobileAndGift", params).then((res) => {
            if (res.code === 200) {
              this.getData();
              _this.$message.success(res.msg);
            } else {
              _this.$message.error(res.msg);
            }
            this.fullscreenLoading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-input {
  width: 99%;
}
/deep/ .el-input__inner {
  border: none;
  background-color: transparent;
}
/deep/ .el-form-item__error {
  left: 20px;
}
/deep/.el-input-group__prepend {
  border: none;
  padding: 0 10px;
}
.el-button {
  margin-top: 20px;
  width: 95%;
  border-radius: 10px;
}
.login {
  background-color: #fff;
  height: 100vh;
  overflow: auto;
}
.login-form {
  margin-top: 50px;
}
.form-bg {
  background-color: #f5f5f5;
  border-radius: 10px; /* 设置圆角，20px 为圆角半径 */
  padding: 5px 0;
}
.getCode {
  width: 100% !important;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .el-button {
    width: 80%;
  }
  .login-form {
    padding: 0 30px;
  }
}
</style>
